.content-children {
    .content-home-button {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;

        button {
            margin: 5px;
        }
    }

    .content-home-post {
        padding: 5px;
    }


}