.content-invoice{
    padding: 5px;
}

.pix-detail{
    display: flex;
    flex-direction: column;
    align-items: center;

    input{
        margin-top: 10px;
        margin-bottom: 10px;

    }

    img{
        max-width: 350px;
    }
}