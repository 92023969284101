.login-content {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    .login-content-div {
        height: 100%;

        .logo{
            display: flex;
            margin-bottom: 26px;
            justify-content: center;

        }

        .form-login {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            .login-options {
                display: flex;
                justify-content: center;
                margin-bottom: 0px;
            }

            .login-submit {
                display: flex;
                justify-content: center;
            }
        }
    }
}