
.menu-toggle {
    visibility: hidden;
}
@media screen and (max-width: 760px) {

    .siderbar-menu {
        display: none;
        
    }

    .siderbar-menu.active {
        position: absolute;
        display: flex;
        height: 100dvh;
        width: 100%;
        z-index: 2;

        div{
            width: 100%;
        }
    }

    .menu-toggle {
        display: flex;
        flex-direction: column;
        height: 48px;
        margin: 8px;
        visibility: visible;
    }
}

.content-children{
    @media screen and (max-width: 460px) {
        padding: 0!important;
    }
}

